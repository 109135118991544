import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Loading from "assets/img/load.gif"
import './App.css';
import globalRouter from './globalRouter.ts';

//layouts
import AuthLayout from './layouts/auth-layouts';
import AppLayout from './layouts/app-layouts';

// Auth Routes
const Login = lazy(() => import("./views/auth/login"));
const Register = lazy(() => import("./views/auth/register"));
const ForgotPassword = lazy(() => import("./views/auth/forgot-password"));
const ResetPassword = lazy(() => import("./views/auth/reset-password"));

//App Routes
const Dashboard = lazy(() => import("./views/app/dashboard"));
const AllEmployees = lazy(() => import("./views/app/employees/all-employees"));
const EmployeeProfile = lazy(() => import("./views/app/profile/employee-profile"));
const Holidays = lazy(() => import("./views/app/employees/holidays"));
const LeavesAdmin = lazy(() => import("./views/app/employees/leaves-admin"));
const LeavesEmployee = lazy(() => import("./views/app/employees/leaves-employee"));
const LeavesSettings = lazy(() => import("./views/app/employees/leave-settings"));
const AttendanceAdmin = lazy(() => import("./views/app/employees/attendance-admin"));
const AttendanceEmployee = lazy(() => import("./views/app/employees/attendance-employees"));
const Department = lazy(() => import("./views/app/employees/department"));
const Designation = lazy(() => import("./views/app/employees/designation"));
const Attendance = lazy(() => import("./views/app/employees/attendance"));
const TimeSheet = lazy(() => import("./views/app/employees/time-sheet"));
const ShiftSchedule = lazy(() => import("./views/app/employees/shify-schedule"));
const OverTime = lazy(() => import("./views/app/employees/over-time"));

const DashboardEmp = lazy(() => import("./views/app/employees/dashboard-emp"));

const EmployeeSalary = lazy(() => import("./views/app/hr/employee-salary"));
const PaySlip = lazy(() => import("./views/app/hr/pay-slip"));
const Additions = lazy(() => import("./views/app/hr/pay-roll-items/additions"));
const Deductions = lazy(() => import("./views/app/hr/pay-roll-items/deductions"));
const OverTimes = lazy(() => import("./views/app/hr/pay-roll-items/over-time"));

const Profile = lazy(() => import("./views/app/profile/my-profile"));
const BankStatutory = lazy(() => import("./views/app/profile/my-profile/bank-statutory"));
const EmployeeBankStatutory = lazy(() => import("./views/app/profile/employee-profile/bank-statutory"));
const CompanySettings = lazy(() => import("./views/app/settings/companySettings"));
const CustomFieldSettings = lazy(() => import('./views/app/settings/customFieldSettings'));
const Roles = lazy(() => import("./views/app/settings/roles"));


function App() {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  return (
    <Suspense fallback={<div className="page-loading"><img src={Loading} alt={Loading} /></div>}>
      <Routes>

        <Route element={<AuthLayout />}>
          <Route exact path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>

        <Route element={<AppLayout />}>

          <Route exact path="/dashboard" element={<Dashboard />} />

          <Route path="/employees" element={<Navigate to="/employees/all-employees" />} />
          <Route path="/employees/all-employees" element={<AllEmployees />} />
          <Route path="/employees/profile" element={<EmployeeProfile />} />
          <Route path="/employees/holidays" element={<Holidays />} />
          <Route path="/employees/leaves-admin" element={<LeavesAdmin />} />
          <Route path="/employees/leaves-employee" element={<LeavesEmployee />} />
          <Route path="/employees/leaves-settings" element={<LeavesSettings />} />
          <Route path="/employees/attendance-admin" element={<AttendanceAdmin />} />
          <Route path="/employees/attendance-employee" element={<AttendanceEmployee />} />
          <Route path="/employees/department" element={<Department />} />
          <Route path="/employees/designation" element={<Designation />} />
          <Route path="/employees/attendance" element={<Attendance />} />
          <Route path="/employees/timesheet" element={<TimeSheet />} />
          <Route path="/employees/shify-schedule" element={<ShiftSchedule />} />
          <Route path="/employees/overtime" element={<OverTime />} />
          <Route path="/employees/dashboard" element={<DashboardEmp />} />

          <Route path="/hr" element={<Navigate to="/hr/employee-salary" />} />
          <Route path="/hr/employee-salary" element={<EmployeeSalary />} />
          <Route path="/hr/payslip" element={<PaySlip />} />

          <Route path="/hr/pay-roll-items" element={<Navigate to="/hr/pay-roll-items/additions" />} />
          <Route path="/hr/pay-roll-items/additions" element={<Additions />} />
          <Route path="/hr/pay-roll-items/deductions" element={<Deductions />} />
          <Route path="/hr/pay-roll-items/over-time" element={<OverTimes />} />

          <Route path="/profile" element={<Navigate to="/profile/my-profile" />} />
          <Route path="/profile/my-profile" element={<Profile />} />
          <Route path="/profile/bank-statutory" element={<BankStatutory />} />
          <Route path="/employee/bank-statutory" element={<EmployeeBankStatutory />} />
          <Route path="/settings/company-settings" element={<CompanySettings />} />

          <Route path='/settings/custom-field-settings' element={<CustomFieldSettings />} />
          <Route path='/settings/roles' element={<Roles />} />
        </Route>

      </Routes>
    </Suspense>
  );
}

export default App;
